import React, { ForwardedRef, ReactElement, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SuggestItem,
  SuggestionListItem,
  SuggestionResponse,
  SearchTypes
} from 'models';
import { ReactComponent as MagnifierIcon } from '../../../images/icon/magnifier.svg';
import css from './SuggestionList.module.scss';
import SuggestionItem from './suggestionItem/SuggestionItem';
import classNames from 'classnames';
import EmptySearchItem from './suggestionItem/EmptySearchItem';
import SearchSelector from 'pages/frontpage/elements/searchSelector/SearchSelector';

interface SuggestionListProps {
  readonly query: string;
  readonly suggestionList: SuggestionListItem[];
  readonly focusedIndex: number;
  readonly handleQuerySearch: () => void;
  readonly handleSelectOption: (option: string, categoryName: string) => void;
  readonly suggestions?: SuggestionResponse;
  readonly isFrontPage?: boolean;
}

export default forwardRef(function SuggestionList(
  props: SuggestionListProps,
  ref: ForwardedRef<HTMLUListElement>
): ReactElement {
  const {
    query,
    suggestionList,
    focusedIndex,
    handleQuerySearch,
    handleSelectOption,
    suggestions,
    isFrontPage
  } = props;
  const { t } = useTranslation('translations');
  const hasResults = !!suggestionList.length;

  const searchCheckboxes: SearchTypes[] = [
    {
      type: 'PLANNING',
      label: 'frontpage.planned_proc'
    },
    {
      type: 'COMPETITION',
      label: 'frontpage.active_proc'
    },
    {
      type: 'RESULT',
      label: 'frontpage.result_proc'
    }
  ];

  const findFocusedIndex = (item: SuggestItem, category: string) => {
    const index = suggestionList?.findIndex(
      (suggestionItem) =>
        suggestionItem.value === item.value &&
        suggestionItem.categoryName === category
    );
    return (index ?? 0) + 1;
  };

  return (
    <ul
      ref={ref}
      id="suggestion_box"
      className={css.suggestion_box}
      role="listbox"
      data-cy="suggestion_result"
      tabIndex={-1}
      key="suggestion_box"
      aria-activedescendant={hasResults ? `option_${focusedIndex}` : 'option_0'}
    >
      {query.length >= 2 && (
        <li
          role="option"
          id="option_0"
          aria-selected={focusedIndex === 0}
          tabIndex={-1}
          onClick={handleQuerySearch}
          className={classNames(
            css.list_item,
            focusedIndex === 0 ? css.focused : ''
          )}
          onKeyDown={(event) => event.key === 'Enter' && handleQuerySearch()}
        >
          <MagnifierIcon aria-hidden="true" />
          {t('search.show_all_results')} {query}
        </li>
      )}
      {!!suggestions &&
        query.length >= 2 &&
        Object.entries(suggestions).map(([categoryName, category]) => [
          category.items.length > 0 && (
            <React.Fragment key={categoryName}>
              <li
                key={t(`common.${categoryName}`)}
                tabIndex={-1}
                role="presentation"
                aria-hidden="true"
                data-cy="suggestions"
                className={classNames(css.suggestion_group, css.categoryName)}
              >
                {t(`common.${categoryName}`)}
              </li>
              {category.items.map((item: SuggestItem) => (
                <SuggestionItem
                  key={categoryName === 'cpvCodesLabel' ? item.id : item.value}
                  item={item}
                  focused={
                    focusedIndex === findFocusedIndex(item, categoryName)
                  }
                  categoryName={t(`common.${categoryName}`)}
                  onClick={() => handleSelectOption(item.id, categoryName)}
                  focusedIndex={findFocusedIndex(item, categoryName)}
                />
              ))}
            </React.Fragment>
          )
        ])}
      {query.length <= 1 && isFrontPage && (
        <>
          <SearchSelector
            focusedIndex={focusedIndex}
            searchCheckboxes={searchCheckboxes}
            aria-hidden="true"
          />
          <EmptySearchItem
            key={'empty-search-box'}
            data-cy="empty_search-box"
            aria-selected={focusedIndex === 3}
            focusedIndex={focusedIndex}
            aria-hidden="true"
          />
        </>
      )}
    </ul>
  );
});
