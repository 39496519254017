import React, { ReactElement, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import css from '../locationSelector/LocationSelector.module.scss';
import ProcurementItem from './ProcurementItem';
import {
  Procurement,
  procurementList,
  ProcurementType,
  TypeItem
} from 'models';
import { isEqualLists } from 'shared/utils';

interface Props {
  readonly typeItems: TypeItem[] | undefined;
}

export default function ProcurementSelector({
  typeItems
}: Props): ReactElement {
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const typeFromUrl = searchParams.get('type');

  useEffect(() => {
    const onlyChildNodes = getOnlyChildNodes(selectedTypes);
    if (onlyChildNodes && shouldUpdateUrl(onlyChildNodes)) {
      searchParams.set('page', '1');
      searchParams.set('type', onlyChildNodes.join(','));
      setSearchParams(searchParams);
    }
  }, [selectedTypes]);

  useEffect(() => {
    if (typeFromUrl) {
      const completeList = typeFromUrl
        .split(',')
        .flatMap((value) => findParentProcurement(value));

      setSelectedTypes(completeList);
    } else {
      setSelectedTypes([]);
    }
  }, [typeFromUrl]);

  const shouldUpdateUrl = (onlyChildNodes: string[]) => {
    const types = typeFromUrl?.split(',') ?? [];

    return !isEqualLists(types, onlyChildNodes);
  };

  const findParentProcurement = (type: string): string[] => {
    for (const procurement of procurementList) {
      if (procurement.children.includes(Procurement[type])) {
        return [procurement.parent, Procurement[type]];
      }
    }
    return [Procurement[type]];
  };

  function toggleType(procurement: ProcurementType, isIndeterminate?: boolean) {
    const updatedSelection = new Set(selectedTypes);

    if (isIndeterminate) {
      removeChildren(updatedSelection, procurement);
    } else {
      updatedSelection.has(procurement)
        ? removeItemAndChildren(updatedSelection, procurement)
        : updatedSelection.add(procurement);
    }

    if (!updatedSelection.size) {
      searchParams.delete('type');
      searchParams.set('page', '1');
      setSearchParams(searchParams);
    }

    setSelectedTypes([...updatedSelection]);
  }

  function getOnlyChildNodes(selectedItems: string[]): string[] | undefined {
    if (!selectedItems.length) return undefined;
    return selectedItems.filter(
      (item) =>
        !procurementList
          ?.find((value) => value.parent === item)
          ?.children.some((child) => selectedItems.includes(child))
    );
  }

  function removeItemAndChildren(
    updatedSelection: Set<string>,
    procurement: ProcurementType
  ) {
    updatedSelection.delete(procurement);
    removeChildren(updatedSelection, procurement);
  }

  const removeChildren = (
    updatedSelection: Set<string>,
    procurement: ProcurementType
  ) => {
    const parent = procurementList.find((type) => type.parent === procurement);
    parent?.children.forEach((child) => updatedSelection.delete(child));
  };

  return (
    <div className={css.selector}>
      <ul>
        {procurementList.map((procurement) => (
          <ProcurementItem
            type={procurement}
            typeItems={typeItems}
            selectedTypes={selectedTypes}
            toggleFn={toggleType}
            key={procurement.parent}
          />
        ))}
      </ul>
    </div>
  );
}
