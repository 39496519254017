import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SearchTypes } from 'models';
import css from './SearchSelector.module.scss';
import classNames from 'classnames';

export interface SearchSelectorProps {
  readonly focusedIndex: number;
  readonly searchCheckboxes: Array<SearchTypes>;
}

export default function SearchSelector(
  props: SearchSelectorProps
): ReactElement {
  const { focusedIndex, searchCheckboxes } = props;
  const { t } = useTranslation('translations');

  return (
    <fieldset className={css.search_filter} id="search-selector">
      <div className={css.linkbox}>
        <legend className={css.search_text} aria-hidden="true">
          <h2>{t('frontpage.search_title')}</h2>
        </legend>
        <div className={css.linklist}>
          {searchCheckboxes.map((element: SearchTypes, index) => {
            const tolink =
              element.type === 'COMPETITION'
                ? `/search?page=1&type=${element.type}&status=ACTIVE`
                : `/search?page=1&type=${element.type}`;
            const isFocused = focusedIndex === index;

            return (
              <Link
                aria-hidden="true"
                to={tolink}
                key={index}
                className={classNames(css.link, isFocused ? css.linkfocus : '')}
                id={element.type}
                aria-selected={isFocused}
              >
                {t(element.label)}
              </Link>
            );
          })}
        </div>
      </div>
    </fieldset>
  );
}
