import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Location, Locations } from 'models';
import css from './LocationSelector.module.scss';
import LocationItem from './locationItem/LocationItem';
import useLocations from 'shared/useLocations';
import LocationSkeleton from 'pages/searchpage/elements/filters/locationSelector/LocationSkeleton';
import { isEqualLists } from 'shared/utils';

interface Props {
  readonly locationItems: Locations[] | undefined;
}

export default function LocationSelector({
  locationItems
}: Props): ReactElement {
  const { t } = useTranslation('translations');
  const { status, locations, flatLocations } = useLocations();
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const locationFromUrl = searchParams.get('location');

  useEffect(() => {
    if (status === 'success') {
      const querySelection = locationFromUrl?.split(',') ?? [];
      const completeValueSet: string[] = querySelection.flatMap((value) =>
        getParentLocations(value).map((location) => location.id)
      );
      setSelectedLocations(completeValueSet);
    }
  }, [status, locationFromUrl]);

  useEffect(() => {
    const onlyChildNodes = getOnlyChildNodes(selectedLocations);
    if (onlyChildNodes && shouldUpdateUrl(onlyChildNodes)) {
      searchParams.set('page', '1');
      searchParams.set('location', onlyChildNodes.join(','));
      setSearchParams(searchParams);
    }
  }, [selectedLocations]);

  const shouldUpdateUrl = (onlyChildNodes: string[]) => {
    const locations = locationFromUrl?.split(',') ?? [];

    return !isEqualLists(locations, onlyChildNodes);
  };

  const getOnlyChildNodes = (
    selectedLocations: string[]
  ): string[] | undefined => {
    if (!selectedLocations.length) return undefined;

    return selectedLocations.filter(
      (locationValue) =>
        !flatLocations
          ?.find((location: Location) => location.id === locationValue)
          ?.children.some((child: Location) =>
            selectedLocations.includes(child.id)
          )
    );
  };

  const getParentLocations = (value: string): Location[] => {
    const norway = 'NO0';
    const location = flatLocations?.find(
      (location: Location) => location.id === value
    );
    if (!location) return [];

    return location.parent && location.parent !== norway
      ? [location, ...getParentLocations(location.parent)]
      : [location];
  };

  const addLocationAndParents = (
    updatedSelection: Set<string>,
    location: Location | undefined
  ) => {
    if (location) {
      updatedSelection.add(location.id);
      addLocationAndParents(
        updatedSelection,
        flatLocations?.find((l: Location) => l.id === location.parent)
      );
    }
  };

  const removeChildren = (
    updatedSelection: Set<string>,
    location: Location
  ) => {
    location.children.forEach((child) => {
      updatedSelection.delete(child.id);
      removeChildren(updatedSelection, child);
    });
  };

  const removeLocationAndChildren = (
    updatedSelection: Set<string>,
    location: Location
  ) => {
    updatedSelection.delete(location.id);
    removeChildren(updatedSelection, location);
  };

  const toggleLocation = (location: Location, isIndeterminate?: boolean) => {
    const updatedSelection = new Set(selectedLocations);

    if (isIndeterminate) {
      removeChildren(updatedSelection, location);
    } else {
      updatedSelection.has(location.id)
        ? removeLocationAndChildren(updatedSelection, location)
        : addLocationAndParents(updatedSelection, location);
    }

    if (!updatedSelection.size) {
      searchParams.delete('location');
      searchParams.set('page', '1');
      setSearchParams(searchParams);
    }

    setSelectedLocations([...updatedSelection]);
  };

  return (
    <div className={css.selector}>
      {status === 'loading' && <LocationSkeleton />}
      {status === 'error' && <div>{t('error.title')}</div>}
      {status === 'success' && locations && (
        <ul>
          {locations.map((location) => (
            <LocationItem
              location={location}
              locationItems={locationItems}
              selectedLocations={selectedLocations}
              toggleFn={toggleLocation}
              key={location.id}
            />
          ))}
        </ul>
      )}
    </div>
  );
}
