import React, { ReactElement, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import css from './Noticepage.module.scss';
import NoticePageSkeleton from 'pages/noticepage/NoticePageSkeleton';
import { NotFound } from '../../components/notFound/NotFound';
import { usePageTitleAndLanguageContext } from '../../context/pageTitleAndLanguageContext/PageTitleAndLanguageContext';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import StatusBar from './elements/statusBar/StatusBar';
import NewerVersionAlert from './elements/newerVersionAlert/NewerVersionAlert';
import FactBox from './elements/factBox/FactBox';
import KGVBox from './elements/KGVBox/KGVBox';
import VersionHistory from './elements/versionHistory/VersionHistory';
import OriginChip from './elements/originChip/OriginChip';
import useNotices from 'shared/useNotices';
import WarningSign from 'pages/noticepage/elements/warningSign/WarningSign';
import EformAccordionPart from './elements/eformAccordionPart/EformAccordionPart';

export default function Noticepage(): ReactElement {
  const { id } = useParams();
  const { status, data } = useNotices(id ?? '');
  const { t } = useTranslation('translations');

  const {
    heading,
    description,
    competitionDocsUrl,
    changeNotices,
    limitedDataFlag: hasLimitedData
  } = data ?? {};
  const { setPageTitle } = usePageTitleAndLanguageContext();

  useEffect(() => {
    scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    if (heading) {
      setPageTitle(heading);
    }
  }, [heading, setPageTitle]);

  const hasNewerVersion = (): boolean => {
    if (!changeNotices) return false;

    const indexInVersionList = changeNotices.findIndex(
      (version) => version.id === id
    );
    return indexInVersionList > 0;
  };

  const getNewestId = (): string => {
    return changeNotices?.[0].id ?? '';
  };

  const getDescription = () => {
    return (
      <p
        className={css.content_section_description}
        data-cy="notice-description"
      >
        {description}
      </p>
    );
  };

  return (
    <>
      {status === 'loading' && <NoticePageSkeleton />}
      {status === 'error' && <NotFound isNotice={true} />}
      {status === 'success' && data && (
        <>
          <Breadcrumbs title={heading} />

          <div className={css.content_wrapper}>
            {hasLimitedData && (
              <WarningSign
                title={t('warning.title')}
                subtitle={t('warning.subtitle')}
              />
            )}
            <StatusBar notice={data} />
            <header>
              <OriginChip isSentToTed={data?.sentToTed} />
              <h1 data-cy="dps-title" className={css.title}>
                {heading}
              </h1>
            </header>
            <div
              className={classnames(css.content_section, {
                [css.has_newer_version]: hasNewerVersion()
              })}
            >
              {hasNewerVersion() && <NewerVersionAlert id={getNewestId()} />}
              {getDescription()}
              <EformAccordionPart notice={data} />
              <FactBox notice={data} />
              <KGVBox url={competitionDocsUrl} />
              {!!changeNotices?.length && (
                <VersionHistory changeNotices={changeNotices} />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
