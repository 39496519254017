import React, { ReactElement } from 'react';

import css from 'components/skeleton/Skeleton.module.scss';

const SkeletonElement = ({ width = '1.5rem' }: { width?: string }) => (
  <span
    style={{
      width,
      height: '1.5rem'
    }}
    className={css.skeleton}
  />
);

const SkeletonRow = ({ width }: { width: string }) => (
  <div style={{ display: 'flex', gap: '0.5rem' }} className={css.loading}>
    <SkeletonElement />
    <SkeletonElement width={width} />
  </div>
);

export default function LocationSkeleton(): ReactElement {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      <SkeletonRow width="11rem" />
      <SkeletonRow width="5rem" />
      <SkeletonRow width="6rem" />
      <SkeletonRow width="7rem" />
      <SkeletonRow width="11rem" />
      <SkeletonRow width="5rem" />
      <SkeletonRow width="6rem" />
    </div>
  );
}
