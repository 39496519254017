import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import css from './NewerVersionAlert.module.scss';
import { ReactComponent as WarningIcon } from '../../../../images/icon/warning.svg';
import { buildUrl } from 'shared/http-common';
import { NOTICES } from 'shared-config';
import { ReactComponent as ArrowRightIcon } from '../../../../images/icon/arrow-right.svg';

interface Props {
  readonly id: string;
}
export default function NewerVersionAlert({ id }: Props): ReactElement {
  const { t } = useTranslation('translations');

  return (
    <Link
      className={css.newer_version_exists}
      data-cy="newer-version"
      to={buildUrl([NOTICES, id])}
    >
      <div className={css.newer_version_title}>
        <WarningIcon aria-hidden={true} />
        <p>{t('notice.newer_version_exists')}</p>
      </div>
      <ArrowRightIcon />
    </Link>
  );
}
