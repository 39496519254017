import React, { ReactElement, useState } from 'react';
import { ReactComponent as SmallCrossIcon } from '../../../../../../images/icon/cross-small.svg';
import { useTranslation } from 'react-i18next';

import css from './Chip.module.scss';
import { ChipContent } from '../ChipList';
import { Location, BuyerWinnerItem } from 'models';
import useLocations from 'shared/useLocations';
import useSearch from 'shared/useSearch';

interface Props {
  readonly chipFn: (chip: ChipContent | undefined) => void;
  chip?: ChipContent;
  readonly isClearAllChip?: boolean;
}

export default function Chip(props: Props): ReactElement {
  const { chip, chipFn, isClearAllChip } = props;
  const { t } = useTranslation('translations');
  const { flatLocations: locations } = useLocations();
  const { data: response } = useSearch();
  const [buyerName, setBuyerName] = useState('');
  const [winnerName, setWinnerName] = useState('');
  const [cpvCategory, setCpvCategory] = useState('');
  const mainCategory = ['RESULT', 'COMPETITION', 'PLANNING'];

  if (chip?.facetKey === 'buyer' && buyerName === '') {
    response?.activeFacets.buyer.items.map((buyer: BuyerWinnerItem) => {
      if (buyer.id === chip?.value) {
        chip.name = buyer.value;
        setBuyerName(buyer.value);
      }
    });
  }
  if (chip?.facetKey === 'winner' && winnerName === '') {
    response?.activeFacets.winner.items.map((winner: BuyerWinnerItem) => {
      if (winner.id === chip?.value) {
        chip.name = winner.value;
        setWinnerName(winner.value);
      }
    });
  }

  if (chip?.facetKey === 'cpvCodesLabel' && cpvCategory === '') {
    response?.activeFacets.cpvCodesLabel.items.map(
      (cpvCategory: BuyerWinnerItem) => {
        if (cpvCategory.id === chip?.value) {
          chip.name = cpvCategory.value;
          setCpvCategory(cpvCategory.value + ' ' + `(${chip?.value})`);
        }
      }
    );
  }

  const setChipText = () => {
    const typePrefix =
      mainCategory.indexOf(chip?.value ?? '') !== -1 ? 'type.' : 'subtype.';
    const typeText = chip?.facetKey
      ? typePrefix + chip.value?.toString().toLowerCase()
      : '';

    switch (chip?.facetKey) {
      case 'type':
        return t(typeText ?? '');
      case 'status':
        return t(`filter.status.${chip.value}`.toLowerCase());
      case 'contractNature':
        return t(`filter.contract_nature.${chip.value}`.toLowerCase());
      case 'location': {
        const location: Location | undefined = locations?.find(
          (location) => location.id === chip?.value
        );
        return location?.name ?? chip?.value;
      }
      case 'cpvCodesLabel': {
        return cpvCategory;
      }
      case 'buyer': {
        return buyerName;
      }
      case 'winner': {
        return winnerName;
      }
      default:
        return chip?.value;
    }
  };

  const getChipType = () => {
    switch (chip?.facetKey) {
      case 'type':
        return t('filter.procurement');
      case 'status':
        return t('filter.status.title');
      case 'contractNature':
        return t('filter.contract_nature.title');
      case 'cpvCodesLabel':
        return t('filter.categories');
      case 'issueDate':
        return t('filter.date');
      case 'location':
        return t('filter.location');
      case 'buyer':
        return t('common.buyer');
      case 'winner':
        return t('common.winner');
    }
  };

  return (
    <button
      className={isClearAllChip ? css.chip_blue : css.chip_light}
      onClick={() => chipFn(chip)}
      aria-label={
        isClearAllChip
          ? t('filter.clear')
          : `${t('filter.remove')}: ${getChipType()}: ${setChipText()}`
      }
      data-cy="chip"
    >
      <span className="filter_text">
        {isClearAllChip ? t('filter.clear') : setChipText()}
      </span>
      <SmallCrossIcon />
    </button>
  );
}
