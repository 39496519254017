import React, { ReactElement } from 'react';

import { Section } from 'models';
import SplitSection from './section/SplitSection';
import FullSection from './section/FullSection';

interface Props {
  readonly section: Section;
}

export default function EformSection(props: Props): ReactElement {
  const { section } = props;

  function isNewSection(section: Section) {
    return section.sections?.length !== 0;
  }

  function isMajorHeader(section: Section) {
    return section.sectionId?.split('.').length === 2;
  }

  function h3Heading(section: Section) {
    return isMajorHeader(section) ? headerValue(section) : '';
  }

  function h4Heading(section: Section) {
    return !isMajorHeader(section) && !section.useLabelAsValue && !section.value
      ? headerValue(section)
      : '';
  }

  const headerValue = (section: Section) => {
    return `${section.sectionId ?? ''} ${section.label ?? ''} ${
      section.value ?? ''
    }`.trim();
  };

  function isFullSection(section: Section) {
    return !!section.sectionId || !section.value || section.useLabelAsValue;
  }

  const renderSectionList = (sections: Section[] | undefined) => {
    return sections?.map((section, index) => {
      return isNewSection(section)
        ? renderNewSection(section, index)
        : renderSection(section, index);
    });
  };

  function renderNewSection(section: Section, index: number) {
    return <EformSection section={section} key={index} />;
  }

  function renderSection(section: Section, index: number) {
    return isFullSection(section)
      ? renderFullSection(section)
      : renderSplitSection(section, index);
  }

  function renderFullSection(section: Section) {
    return (
      <FullSection
        h3={h3Heading(section)}
        h4={h4Heading(section)}
        content={valueOn(section)}
      />
    );
  }

  function renderSplitSection(section: Section, index: number) {
    return (
      <SplitSection
        key={index}
        leftContent={section.label}
        rightContent={section.value ?? ''}
      />
    );
  }

  function valueOn(section: Section): string[] | string | null | undefined {
    if (section.useLabelAsValue) {
      return section.label;
    } else if (section.sections?.length) {
      return [];
    } else {
      return null;
    }
  }

  return (
    <>
      {renderSection(section, 0)}
      <>{renderSectionList(section?.sections)}</>
    </>
  );
}
