import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import css from './Searchpage.module.scss';
import Filters from 'pages/searchpage/elements/filters/Filters';
import Pagination from 'pages/searchpage/elements/pagination/Pagination';
import {
  capitalizeFirstLetter,
  usePageTitleAndLanguageContext
} from '../../context/pageTitleAndLanguageContext/PageTitleAndLanguageContext';
import ResultCount from './elements/resultCount/ResultCount';
import useSearch from 'shared/useSearch';
import SortingSelector from './elements/sortingSelector/SortingSelector';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import ChipList from './elements/filters/chipList/ChipList';
import EmptyState from 'pages/searchpage/elements/emptyState/EmptyState';
import MobileFilter from './elements/mobileFilter/MobileFilter';
import ErrorState from 'pages/searchpage/elements/errorState/ErrorState';
import NoticeCard from 'pages/searchpage/elements/noticeCard/NoticeCard';
import HitsPerPageSelector from './elements/hitsPerPageSelector/HitsPerPageSelector';
import SearchPageSkeleton from 'pages/searchpage/SearchPageSkeleton';
import AutoSuggest from 'components/autoSuggest/AutoSuggest';
import { buildUrl } from 'shared/http-common';
import { CPV } from 'shared-config';
import { ReactComponent as ListDocument } from '../../images/icon/list-document.svg';

export default function Searchpage(): ReactElement {
  const { t } = useTranslation('translations');
  const { setPageTitle } = usePageTitleAndLanguageContext();
  const { numHitsTotal, data: response, isLoading, error } = useSearch();

  const lgBreakpoint = 1180;
  const isLgBreakpoint = window.innerWidth < lgBreakpoint;
  const [isSmallerScreen, setIsSmallerScreen] =
    useState<boolean>(isLgBreakpoint);

  useEffect(() => {
    setPageTitle(capitalizeFirstLetter(t('search.title')));
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () =>
    setIsSmallerScreen(window.innerWidth < lgBreakpoint);

  return (
    <>
      <Breadcrumbs title={t('search.breadcrumb')} />
      <div className={css.filter_wrapper}>
        {!isSmallerScreen && (
          <div className={css.sidebar}>
            <h2 className={css.filter_title}>{t('filter.title')}</h2>
            <Filters facets={response?.facets} />
          </div>
        )}
        <section className={css.result__section}>
          <div className={css.search__section}>
            <div>
              <h1 className={css.search_title}>{t('search.title')}</h1>
              <div data-cy="cpv-filter" className={css.cpv_label_wrapper}>
                <a
                  href={buildUrl([CPV])}
                  className={css.link}
                  data-cy="cpv-link"
                >
                  {t('filter.categories_placeholder')}
                  <ListDocument />
                </a>
              </div>
            </div>

            <AutoSuggest placeholder={t('search.placeholder')} />
            <ChipList />
            <div className={css.sort}>
              {isSmallerScreen && (
                <MobileFilter
                  facets={response?.facets}
                  totalHits={numHitsTotal}
                />
              )}
              {!isSmallerScreen && (
                <ResultCount
                  hitsPerPage={response?.hits?.length}
                  totalHits={numHitsTotal}
                  isLoading={isLoading}
                  data-cy="result_count"
                />
              )}
              <div className={css.select_container}>
                <HitsPerPageSelector />
                <SortingSelector />
              </div>
            </div>
            {isSmallerScreen && (
              <ResultCount
                hitsPerPage={response?.hits?.length}
                totalHits={numHitsTotal}
                isLoading={isLoading}
                data-cy="result_count"
              />
            )}
          </div>
          {isLoading && <SearchPageSkeleton />}
          <ul data-cy="notice-list" className={css.result_list}>
            {response?.hits.map((notice, index) => (
              <NoticeCard
                notice={notice}
                key={notice.id}
                isFirstInList={index === 0}
              />
            ))}
          </ul>
          {error && <ErrorState />}
          {!isLoading && numHitsTotal === 0 && <EmptyState />}
          <Pagination
            numHitsTotal={numHitsTotal}
            isLoading={isLoading}
            error={error}
          />
        </section>
      </div>
    </>
  );
}
