import React, { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import css from './Breadcrumbs.module.scss';
import { SEARCH } from 'shared-config';
import { buildUrl } from 'shared/http-common';

interface Props {
  readonly title?: string | ReactNode;
}

export default function Breadcrumbs({ title }: Props): ReactElement {
  const { state } = useLocation();
  const { t } = useTranslation('translations');
  const isNoticesInUrl = location.pathname.includes('notices');
  const isCpvInUrl = location.pathname.includes('cpv');

  const getSearchUrl = () =>
    state?.queryString
      ? `${buildUrl([SEARCH])}?${state.queryString}`
      : buildUrl([SEARCH]);

  return (
    <div className={css.breadCrumbs} data-cy="breadCrumbs">
      <Link id="start-link" to="/">
        Start
      </Link>
      {(isNoticesInUrl || isCpvInUrl) && (
        <>
          <span aria-hidden={true}>/</span>
          <Link to={getSearchUrl()}>{t('search.breadcrumb')}</Link>
        </>
      )}
      {title && (
        <>
          <span aria-hidden={true}>/</span>
          <span className={css.breadCrumbsTitle}>{title}</span>
        </>
      )}
    </div>
  );
}
