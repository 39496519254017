import React, { ReactElement } from 'react';
import { SuggestItem } from 'models';
import css from '../SuggestionList.module.scss';
import { useTranslation } from 'react-i18next';
import { formatNumbers } from 'shared/utils';
import classNames from 'classnames';

interface Props {
  readonly item: SuggestItem;
  readonly focused: boolean;
  readonly onClick: () => void;
  readonly focusedIndex: number;
  readonly categoryName: string;
}

export default function SuggestionItem(props: Props): ReactElement {
  const { item, focused, onClick, focusedIndex, categoryName } = props;
  const { t } = useTranslation('translations');

  const cpvCode = categoryName.includes('CPV') ? ` - ${item.id} ` : '';
  const ariaLabel =
    categoryName +
    ':' +
    item.value +
    ' ' +
    cpvCode +
    ' ' +
    item.total +
    ' ' +
    t('search.result');

  return (
    <li
      aria-label={ariaLabel}
      id={'option_' + focusedIndex}
      role="option"
      tabIndex={-1}
      aria-selected={focused}
      onClick={onClick}
      className={classNames(css.list_item, focused ? css.focused : '')}
      lang="nb"
      onKeyDown={(event) => event.key === 'Enter' && onClick()}
    >
      <span
        className={css.bold}
        dangerouslySetInnerHTML={{ __html: item.highlight + cpvCode }}
      />
      {formatNumbers(` (${item.total.toString()})`)}
    </li>
  );
}
