import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useSuggestions from 'shared/useSuggestions';
import { isEqualLists } from 'shared/utils';
import SuggestionInput from '../suggestionInput/SuggestionInput';
import SuggestionList from './suggestionList/SuggestionList';
import { Procurement, SuggestItem, SuggestionListItem } from 'models';

interface Props {
  readonly placeholder: string;
  readonly searchFromFrontPage?: (query: string, categoryName?: string) => void;
  readonly isFrontPage?: boolean;
  updateFrontPageFilters?: any;
}

export default function AutoSuggest(props: Props): ReactElement {
  const {
    placeholder,
    searchFromFrontPage,
    isFrontPage,
    updateFrontPageFilters
  } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const searchFromUrl = searchParams.get('searchString');

  const [searchString, setSearchString] = useState<string>(searchFromUrl ?? '');
  const [focusedIndex, setFocusedIndex] = useState<number>(0);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<
    Record<string, string[]>
  >({
    buyer: [],
    winner: [],
    cpvCodesLabel: []
  });

  const { data: suggestions } = useSuggestions(searchString);

  const inputRef = useRef<HTMLInputElement>(null);
  const suggestionListRef = useRef<HTMLUListElement>(null);

  const hasResults = !!suggestions && searchString !== '';
  const isInputFocused = document.activeElement === inputRef.current;
  const isEmptyList: boolean | undefined =
    isFrontPage && searchString.length <= 1;

  const suggestionList: SuggestionListItem[] =
    (suggestions &&
      Object.entries(suggestions).flatMap(([categoryName, category]) =>
        category.items.map((item: SuggestItem) => ({
          value: item.value,
          categoryName: categoryName,
          id: item.id
        }))
      )) ??
    [];

  useEffect(() => {
    const searchString = searchFromUrl ?? '';
    setSearchString(searchString);

    Object.keys(selectedOptions).forEach((categoryName) => {
      const fromUrlList =
        (searchParams.get(categoryName)?.split(',') as string[]) ?? [];
      setSelectedOptions((prev) => ({
        ...prev,
        [categoryName]: fromUrlList
      }));
    });
  }, [searchFromUrl, searchParams]);

  useEffect(() => {
    if (hasResults && suggestionListRef.current) {
      suggestionListRef.current.scrollIntoView({ block: 'nearest' });
    }
  }, [hasResults]);

  useEffect(() => {
    setFocusedIndex(0);
    if (!isFrontPage) {
      setDropdownVisible(
        isInputFocused && searchString !== '' && searchString.length >= 2
      );
    } else {
      setDropdownVisible(isInputFocused);
    }
  }, [searchString]);

  useEffect(() => {
    if (!isInputFocused) setDropdownVisible(false);

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (inputRef.current)
      inputRef.current.addEventListener('keydown', handleKeyPress);
    return () => {
      if (inputRef.current)
        inputRef.current.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    if (searchFromUrl) setSearchString(searchFromUrl);
  }, [searchFromUrl]);

  useEffect(() => {
    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener('focus', handleFocus);
      document.addEventListener('click', handleClickOutside);
      return () => {
        inputElement.removeEventListener('focus', handleFocus);
        document.removeEventListener('click', handleClickOutside);
      };
    }
  }, [searchString, isFrontPage]);

  const shouldUpdateUrl = (selectedStatus: string[], categoryName: string) => {
    const status = selectedOptions[categoryName];
    return !isEqualLists(status, selectedStatus);
  };

  const updateUrlForSelectedItems = (
    categoryName: string,
    selectedItems: string[]
  ) => {
    if (selectedItems.length && shouldUpdateUrl(selectedItems, categoryName)) {
      searchParams.set('page', '1');
      searchParams.set(categoryName, selectedItems.join(','));
      setSearchParams(searchParams);
    }
  };

  const handleFocus = () => {
    if (searchString === '' && isFrontPage) {
      setDropdownVisible(true);
    }
  };

  const handleClickOutside = (event) => {
    const clickOutsideInput =
      inputRef.current && !inputRef.current.contains(event.target);
    const clickOutsideDropdown =
      suggestionListRef.current &&
      !suggestionListRef.current.contains(event.target);
    const clickInsideInput = inputRef?.current?.contains(event.target);

    if (clickOutsideInput && clickOutsideDropdown) {
      setDropdownVisible(false);
    } else if (searchString === '' && clickInsideInput && isFrontPage) {
      setDropdownVisible(true);
    }
  };

  function handleKeyPress(event: KeyboardEvent) {
    const lastIndex: number = isEmptyList ? 4 : suggestionList?.length ?? 0;
    const suggestion = suggestionList?.[focusedIndex - 1];

    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        if (focusedIndex === 0 && !isEmptyList) handleSearch();
        else if (isEmptyList) handleSearch();
        else if (hasResults && suggestionList)
          handleSelectOption(suggestion.id, suggestion.categoryName);
        break;

      case 'Tab':
      case 'Escape':
        setDropdownVisible(false);
        break;

      case 'ArrowDown':
        event.preventDefault();
        setFocusedIndex(focusedIndex === lastIndex ? 0 : focusedIndex + 1);
        break;

      case 'ArrowUp':
        event.preventDefault();
        setFocusedIndex(focusedIndex === 0 ? lastIndex : focusedIndex - 1);
        break;
    }
  }

  const handleSelectOption = (option: string, categoryName: string) => {
    const updatedSelection = new Set(selectedOptions[categoryName]);

    updatedSelection.add(option);

    if (!updatedSelection.size) {
      searchParams.set('page', '1');
      searchParams.delete(categoryName);
      setSearchParams(searchParams);
    }

    setSelectedOptions((prev) => ({
      ...prev,
      [categoryName]: [...updatedSelection]
    }));

    setDropdownVisible(false);
    setFocusedIndex(0);
    removeSearch();
    updateUrlForSelectedItems(categoryName, [...updatedSelection]);
    if (searchFromFrontPage) searchFromFrontPage(option, categoryName);
  };

  const handleSearch = () => {
    if (isEmptyList) {
      setSearchString('');
      switch (focusedIndex) {
        case 0:
          updateFrontPageFilters(Procurement.PLANNING);
          break;
        case 1:
          updateFrontPageFilters(Procurement.COMPETITION);
          break;
        case 2:
          updateFrontPageFilters(Procurement.RESULT);
          break;
        default:
          updateFrontPageFilters('');
          break;
      }
    }

    searchString !== ''
      ? searchParams.set('searchString', searchString)
      : searchParams.delete('searchString');

    searchParams.set('page', '1');
    setSearchParams(searchParams);
    setDropdownVisible(false);
    if (searchFromFrontPage) searchFromFrontPage(searchString);
  };

  const removeSearch = () => {
    if (!searchFromFrontPage) {
      searchParams.delete('searchString');
      searchParams.set('page', '1');
      setSearchParams(searchParams);
    }

    setSearchString('');
    setDropdownVisible(false);
  };

  return (
    <div className="combobox">
      <SuggestionInput
        query={searchString}
        ariaControls="suggestion_box"
        testId="suggestion_input"
        ref={inputRef}
        placeholder={placeholder}
        onChange={(value) => setSearchString(value)}
        onClear={removeSearch}
        onSearch={handleSearch}
        dropdownVisible={dropdownVisible}
        activeDescendant={hasResults ? `option_${focusedIndex}` : 'option_0'}
      />
      {dropdownVisible && (
        <SuggestionList
          ref={suggestionListRef}
          query={searchString}
          suggestionList={suggestionList}
          suggestions={suggestions}
          focusedIndex={focusedIndex}
          handleQuerySearch={handleSearch}
          handleSelectOption={handleSelectOption}
          isFrontPage={isFrontPage}
        />
      )}
    </div>
  );
}
