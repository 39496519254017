import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import css from './StatusBadge.module.scss';
import { StatusType } from 'models';

interface Props {
  readonly status: StatusType;
}

export default function StatusBadge({ status }: Props) {
  const { t } = useTranslation('translations');

  const getStatusText = () => {
    switch (status) {
      case 'ACTIVE':
        return t(`filter.status.active`);
      case 'AWARDED':
        return t(`filter.status.awarded`);
      case 'EXPIRED':
        return t(`filter.status.expired`);
      case 'CANCELLED':
        return t(`filter.status.cancelled`);
    }
  };

  const getStatusClass = () => {
    switch (status) {
      case 'ACTIVE':
        return css.active;
      case 'AWARDED':
        return css.awarded;
      case 'EXPIRED':
        return css.expired;
      case 'CANCELLED':
        return css.cancelled;
    }
  };

  return (
    <span
      data-cy="badge"
      className={classnames(css.badge, getStatusClass())}
      aria-label={`${t('filter.status.title')}: ${getStatusText()}`}
    >
      {getStatusText().toUpperCase()}
    </span>
  );
}
