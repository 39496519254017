export const BASE_URL = process.env.REACT_APP_API_URL ?? '/';
export const SEARCH_API_URL = process.env.REACT_APP_SEARCH_API_URL ?? '/';
export const APPLICATION_INSIGHTS_CONNECTION_STRING =
  process.env.APPLICATION_INSIGHTS_CONNECTION_STRING ?? '';
export const SEARCH = 'search';
export const SUGGEST = 'suggest';
export const NOTICES = 'notices';
export const CPV = 'cpv';
export const CODES = 'codes';
export const CPV_CODES = 'cpvCodes';
export const LOCATION_CODES = 'locationCodes';

export const DOFFIN_CLASSIC_URL = 'https://classic.doffin.no';
export const DFO_URL = 'https://dfo.no';
export const DFO_OM_URL = 'https://dfo.no/nn/om-dfo';
export const DFO_PERSONVERN_URL = 'https://dfo.no/nn/personvern';
export const DFO_ENGLISH_URL =
  'https://dfo.no/en/norwegian-agency-public-and-financial-management-dfo';

export const ANSKAFFELSER_SEARCH_TIPS_URL =
  'https://anskaffelser.no/nn/hjelpesider-doffin/soketips-i-doffin';
export const ANSKAFFELSER_INNOVASJON_URL =
  'https://anskaffelser.no/innovative-anskaffelser-hovedside';
export const ANSKAFFELSER_SAMFUNNSANSVAR_URL =
  'https://anskaffelser.no/nn/berekraftige-offentlege-anskaffingar';
export const ANSKAFFELSER_INNKJOPSLEDELSE_URL =
  'https://anskaffelser.no/innkjopsledelse-hovedside';
export const ANSKAFFELSER_REGELVERK_URL =
  'https://anskaffelser.no/avtaler-og-regelverk';
export const ANSKAFFELSER_PROSESS_URL =
  'https://anskaffelser.no/anskaffelsesprosessen/anskaffelsesprosessen-steg-steg';
export const ANSKAFFELSER_HVA_URL = 'https://anskaffelser.no/hva-skal-du-kjope';
export const ANSKAFFELSER_BRUKERVEILEDNING_URL =
  'https://anskaffelser.no/hjelpesider-doffin/brukerveiledning-doffin';
export const ANSKAFFELSER_OM_URL =
  'https://anskaffelser.no/nn/hjelpesider-doffin/om-doffin';
export const ANSKAFFELSER_OPPDRAGSGIVERE_URL =
  'https://anskaffelser.no/nn/hjelpesider-doffin/doffin-oppdragsgivarar';
export const ANSKAFFELSER_TILBYDERE_URL =
  'https://anskaffelser.no/nn/hjelpesider-doffin/doffin-tilbydere';
export const ANSKAFFELSER_EFORMS_NYE_DOFFIN =
  'https://anskaffelser.no/dfos-arbeid-med-offentlige-anskaffelser/program-digitale-anskaffelser/nye-kunngjoringsskjemaer-eforms-og-ny-doffin';

export const MATOMO = {
  BASE_URL: 'https://doffin.no/',
  SITE_ID: 38,
  TRACKER_URL: 'https://sporing.dfo.no'
};
