import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import Chip from './chip/Chip';
import css from './ChipList.module.scss';
import { FacetsKey } from 'models';
import { formatDate } from 'shared/utils';

export interface ChipContent {
  facetKey: FacetsKey;
  value: string | undefined;
  name?: string;
}

export default function ChipList(): ReactElement {
  const [chipList, setChipList] = useState<ChipContent[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation('translations');

  const fromDate = searchParams.get('fromDate');
  const toDate = searchParams.get('toDate');
  const location = searchParams.get('location')?.split(',') ?? [];
  const cpvCode = searchParams.get('cpvCodesLabel')?.split(',') ?? [];
  const type = searchParams.get('type')?.split(',') ?? [];
  const status = searchParams.get('status')?.split(',') ?? [];
  const contractNature = searchParams.get('contractNature')?.split(',') ?? [];
  const buyer = searchParams.get('buyer')?.split(',') ?? [];
  const winner = searchParams.get('winner')?.split(',') ?? [];

  useEffect(() => {
    setChipFilters();
  }, [searchParams]);

  const removeAllFn = () => {
    setSearchParams((searchParams) => {
      searchParams.delete('fromDate');
      searchParams.delete('toDate');
      searchParams.delete('location');
      searchParams.delete('cpvCodesLabel');
      searchParams.delete('type');
      searchParams.delete('contractNature');
      searchParams.delete('status');
      searchParams.delete('buyer');
      searchParams.delete('winner');

      searchParams.set('page', '1');
      return searchParams;
    });
  };

  const setChipFilters = () => {
    const selectedChips: ChipContent[] = [];

    const createChip = (facetKey: FacetsKey, value: string) => {
      selectedChips.push({ facetKey, value });
    };

    cpvCode?.length &&
      cpvCode.forEach((cpv) => createChip('cpvCodesLabel', cpv));
    type?.length && type.forEach((type) => createChip('type', type));
    status?.length && status.forEach((status) => createChip('status', status));
    contractNature?.length &&
      contractNature.forEach((contractNature) =>
        createChip('contractNature', contractNature)
      );

    location?.length &&
      location.forEach((location) => createChip('location', location));
    buyer?.length && buyer.forEach((buyer) => createChip('buyer', buyer));
    winner?.length && winner.forEach((winner) => createChip('winner', winner));

    if (fromDate && toDate) {
      createChip(
        'issueDate',
        `${formatDate(fromDate)} - ${formatDate(toDate)}`
      );
    } else {
      fromDate &&
        createChip('issueDate', `${t('common.after')} ${formatDate(fromDate)}`);
      toDate &&
        createChip('issueDate', `${t('common.before')} ${formatDate(toDate)}`);
    }
    setChipList(selectedChips);
  };

  const removeFilters = (chip: ChipContent) => {
    if (chip.facetKey === 'issueDate') {
      searchParams.delete('fromDate');
      searchParams.delete('toDate');
    } else {
      const chipString = searchParams.get(chip.facetKey) ?? '';
      const newChipList = chipString
        .split(',')
        .filter((elem) => elem !== chip.value);
      if (newChipList.length) {
        searchParams.set(chip.facetKey, newChipList.join(','));
      } else {
        searchParams.delete(chip.facetKey);
      }
      searchParams.set('page', '1');
    }
    setSearchParams(searchParams);
  };

  return (
    <>
      {chipList.length > 0 && (
        <ul className={css.chip_list} data-cy="chip_list">
          <li>
            <Chip isClearAllChip={true} chipFn={removeAllFn} />
          </li>
          {chipList.map((chip) => (
            <li key={chip.value}>
              <Chip
                chip={chip}
                chipFn={(chip) => {
                  chip && removeFilters(chip);
                }}
              />
            </li>
          ))}
        </ul>
      )}
    </>
  );
}
